
.purchace-popup {
    .close {
        color: inherit;
        opacity: 1;

        @include media-breakpoint-down(md) {
            position: absolute;
            top: 7px;
            right: 10px;
        }
    }
}

.quick-action-toolbar {
    .card {
        .card-header {
            color: $white;
            padding: 12px 30px;
            background-image: linear-gradient(to left, #fad961, #f76b1c);
            border: 0;
            border-radius: 0;
            h5 {
                font-weight: 600;
                font-size: 18px;
            }

            p {
                color: inherit;
            }
            i {
                margin-left: 15px;
                align-self: center;
            }
        }

        .quick-action-btns {
            .btn-light {
                background-color: transparent;
                border: none;
                font-size: 14px;
                color: #111111;
                font-weight: 600;
                display: flex;
                align-items: center;

                i {
                    color: #626262;
                    margin-right: 15px;
                    font-size: 18px;
                    .rtl & {
                        margin-left: 15px;
                        margin-right: 0;
                    }
                }
            }
            .btn-wrapper{
                border-right: 1px solid $border-color;
                display: flex;
                justify-content: center;

                button {
                    display: flex;
                    align-items: center;
                }

                &:first-child {
                    @media screen and (max-width:767px )  {
                        border-bottom: 1px solid $border-color;
                    }
                    @media screen and (max-width:576px )  {
                        border-right: none;
                    }
                }

                &:nth-child(2) {
                    @media screen and (max-width:767px )  {
                        border-right: none;
                        border-bottom: 1px solid $border-color;
                    }
                }

                &:nth-child(3) {
                    @media screen and (max-width:576px )  {
                        border-right: none;
                        border-bottom: 1px solid $border-color;
                    }
                }

                &:last-child {
                    border: none;
                }
            }
        }
    }
}

.income-expense-summary-chart-text {
    h5 {
        font-size: 18px;
        font-weight: 600;
    }
    h3 {
        font-size: 24px;
        font-weight: bold;
    }
}

.income-expense-summary-chart-legend {
    display: flex;
    align-items: center;

    span {
        display: inline-block;
        width: 14px;
        height: 14px;
        border: 2px solid transparent;
        margin-right: 10px;

        .rtl & {
            margin-left: 10px;
            margin-right: 0;
        }
    }
}

#income-expense-summary-chart-daterange {
    width: 280px;
    border-radius: 4px;
    border: solid 1px $border-color;
    background-color: $white;
    color: #969696;

    .input-group-text {
        background-color: transparent;
        color: inherit;
        padding: 10px;
        border: 0;
    }
    .form-control {
        border: 0;
        font-size: 12px;
        font-weight: 600;
        color: inherit;
        padding: 0;
    }
}

.income-expense-summary-chart {
    direction: ltr; //Fix for labels displacement in rtl
    .ct-chart {
        .ct-series {
            .ct-line {
                stroke-width: 3px;
                stroke-dasharray: 0;
            }

            &-a {
                .ct-line {
                    stroke: #6469df;
                }
                .ct-area {
                    fill: #6469df;
                }
            }

            &-b {
                .ct-line {
                    stroke: #33c92d;
                }
                .ct-area {
                    fill: #33c92d;
                }
            }
        }

        .ct-labels {
            .ct-label {
                &.ct-horizontal {
                    @media screen and (max-width: 480px) {
                        transform: rotate(-90deg)translateY(-50%);
                    }
                }
            }
        }
    }
}

.ct-chart{
    .ct-line {
        stroke-dasharray: 0;
    }
}

.report-summary-header {
    border-bottom: solid 1px $border-color;
}

.report-inner-cards-wrapper {
    padding-top: 25px;

    .report-inner-card {
        display: flex;
        border-right: solid 1px $border-color;
        padding: 0 20px;

        .rtl & {
            border-right: none;
            border-left: solid 1px $border-color;
        }

        @media screen and (max-width: 1199px) {
            padding-top: 12.5px;
            padding-bottom: 12.5px; 
        }

        &:first-child {
            padding-left: 15px;

            .rtl & {
                padding-right: 15px;
                padding-left: 40px;
            }

            @media screen and (max-width: 1199px) {
                border-bottom: solid 1px $border-color;
            }

            @media screen and (max-width:767px) {
                border-right: none;
                padding-right: 12.5px;

                .rtl & {
                    border-left: none;
                    padding-left: 12.5px;
                }
            }
        }

        &:nth-child(2) {

            @media screen and (max-width: 1199px) {
                padding-right: 12.5px;
                border-bottom: solid 1px $border-color;
                border-right: none;

                .rtl & {
                    border-left: none;
                    padding-left: 12.5px;
                    padding-right: 40px;
                }
            }

            @media screen and (max-width:767px) {
                padding-left: 12.5px;

                .rtl &{
                    padding-right: 12.5px;
                }
            }

        }

        &:nth-child(3) {
            @media screen and (max-width: 1199px) {
                padding-left: 12.5px;

                .rtl & {
                    padding-left: 40px;
                    padding-right: 12.5px;
                }
            }
            @media screen and (max-width:767px) {
                border-right: none;
                padding-right: 12.5px;
                border-bottom: solid 1px $border-color;

                .rtl & {
                    border-left: none;
                    padding-left: 12.5px;
                }
            }
        }

        &:last-child {
            border-right: 0;
            padding-right: 15px;

            .rtl & {
                border-left: none;
                padding-left: 15px;
                padding-right: 40px;
            }

            @media screen and (max-width:767px) {
                border-right: none;
                padding-left: 12.5px;

                .rtl & {
                    border-left: none;
                    padding-right: 12.5px;
                }
            }
        }

        .inner-card-text {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }

        .report-title {
            font-size: 14px;
            font-weight: 600;
            color: #111111;
        }

        h4 {
            font-weight: bold;
        }

        .report-count {
            font-size: 14px;
            color: #8e32e9;
        }

        .inner-card-icon {
            width: 62px;
            height: 62px;
            font-size: 25px;
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: auto;

            .rtl & {
                margin-left: 0;
                margin-right: auto;
            }
        }
    }
}

.aligner-wrapper {
    position: relative;
    .wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.square-indicator {
    display: inline-block;
    width: 13px;
    height: 13px;

    & + p {
        font-size: 12px;
        font-weight: 600;
        color: #a7afb7;
    }
}

#performance-indicator-chart {
    .ct-series {

        &.ct-series-a {
            line {
                stroke: theme-color(danger);
            }
        }

        &.ct-series-b {
            line {
                stroke: theme-color(info);
            }
        }

        &.ct-series-c {
            line {
                stroke: theme-color(primary);
            }
        }

        .ct-bar {
            stroke-width: 8px;
            stroke-linecap: round;

            @media screen and (max-width: 480px) {
                stroke-width: 4px;
            }
        }

    }

    .ct-labels {
        .ct-label {
            &.ct-horizontal {
                @media screen and (max-width: 480px) {
                    transform: rotate(-90deg)translateY(20%);
                }
            }
        }
    }
}

.performane-indicator-card {
    .data-time-range {
        color: #a7afb7;
        font-size: 12px;
        font-weight: 600;
        margin-left: 20px;

        &.active {
            color: #111111;
        }
    }
}
.table {
    td {
        img {
            &.gateway-icon {
                width: 20px;
                height: 20px;
                min-width: 20px;
            }
        }
    }
}

.pagination {
    .page-item {
        .page-link {
            font-size: 12px;
            font-weight: 600;

            @media screen and (max-width:576px) {
                padding: 0.25rem 0.5rem;
            }
        }
    }
}